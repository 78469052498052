import Vue from 'vue'
import { Notify } from 'vant'
Vue.use(Notify)
let notify // 通知
// 显示 Notify 消息提示//type可以为primary、success、warning、danger
// type，titleMsg
export const showNotify = params => {
  const { type, titleMsg } = params
  let newType = !type ? 'primary' : type
  let background
  switch (newType) {
    case 'primary':
      background = '#48D58E'
      break
    case 'success':
      background = '#25b9ff'
      break
    case 'warning':
      background = '#E6A23C'
      break
    default:
      background = '#F56C6C'
      break
  }
  notify = Notify({
    type: newType,
    message: titleMsg,
    duration: 2000,
    className: 'notifyClass',
    background: background
  })
}
export const hideNotify = () => {
  notify.clear()
}

export default {
  showNotify,
  hideNotify
}
