var messages = {
  privacy: {
    title: "Vancheen服务使用提示",
    text1: "感谢您的信任，欢迎使用Vancheen！",
    text2: "为更好地保护您的权益，请您务必仔细阅读并充分理解Vancheen的",
    text3: "《个人用户服务协议》及《隐私政策》",
    text4:
      "如您点击“同意”，即表明您已充分阅读、理解并接受上述协议的全部内容。如您“不同意”本协议及其补充协议中的任意条款约定，您可以退出本页面。"
  },
  meta: {
    homePage: "首页",
    userPage: "我",
    productDetails: "商品详情",
    store: "店铺",
    userManagement: "用户管理",
    attentionGoods: "我的收藏",
    attentionShop: "店铺关注",
    browsingHistory: "浏览记录",
    supplierDetails: "供应商详情",
    userSet: "用户设置",
    declaration: "声明",
    orderdetail: "订单详情",
    search: "搜索",
    special: "热门专区",
    editAddress: "编辑地址",
    shippingAddress: "地址管理",
    addAddress: "新增地址",
    allProducts: "全部分类",
    authentication: "注册商家",
    order: "订单",
    newsCenter: "消息中心",
    newArrival: "最新上架",
    morePopular: "热销商品",
    videoCollection: "视频集",
    more: "更多",
    Agreement: "个人用户服务协议",
    privacy: "隐私政策",
    agree: "同意",
    disagree: "不同意",
    submit: "提交",
    confirm: "确定",
    shippingRules: "发货及派送",
    returnRules: "退货条件及规则",
    downloadText: "下载 Vancheen App 发现更多精品",
    openShop: "打开店铺",
    viewShop: "进店逛逛",
    videoError: "此视频暂无法播放，请稍后再试",
    nextworkError:"网络错误"
  },
  auth: {
    login: "登录",
    logInSuccess: "登录成功",
    logInError: "请先填写用户名与密码",
    register: "注册",
    setAccountInfo: "设置账户信息",
    forgetPassword: "忘记密码",
    validEmail: "验证邮箱",
    setNewPassword: "设置新密码",
    finishPasswrod: "完成",
    forgottenPassword: "忘记密码?",
    sendAuthCode: "获取验证码",
    next: "下一步",
    noAccountGoRegister: "没有账号？去注册",
    existingAccountToLogin: "已有账号？去登录",
    placeholderEmail: "请输入邮箱",
    placeholderPassword: "请输入密码",
    placeholderNewPassword: "请输入新的密码",
    placeholderAuthPassword: "确认密码",
    placeholderEmailCode: "请输入邮箱验证码",
    placeholderNickname: "请输入昵称",
    errorEmail: "请输入正确的邮箱地址",
    errorEmailNotExist: "账号不存在",
    errorPasswordNotMatch: "新的密码与确认密码不一致",
    passwordValid: "密码由6-20位英文字母、数字或符号组成",
    nicknameValid: "昵称5-25个字符、可用于登录找回密码",
    agreedVancheenRegulations: "已同意《Vancheen服务条款》",
    backLogin: "返回登录",
    resetPasswordStep1: "1.验证注册邮箱", // 1.Verify registered email
    resetPasswordStep2: "2.验证邮箱", // 2.Verify code
    resetPasswordStep3: "3.设置新密码", // 3.Set new password
    resetPasswordStep4: "4.完成", // 4.Finish
    pleaseEnterEmail: "请先输入正确的邮箱",
    verificationCodeInfo: "已发送验证码",
    verificationCodeErroe: "请先填写验证码与正确邮箱",
    inputEmail: "请输入邮箱",
    username: "用户名",
    email: "邮箱",
    pasTitle: "登录密码",
    inputPaw: "请输入登录密码",
    pawInfo: "密码由6-20位英文字母+数字+符号组成",
    registerSuccess: "注册成功",
    correctError: "请先输入正确的密码",
    registerInfo: "注册成功,是否跳转App下载页",
    editName: "修改昵称",
    inputNewName: "请输入新的昵称",
    inputYourName: "请输入您的昵称",
    tick: "请勾选",
    rulesAndProtocols: "我已阅读并同意个人用户服务协议和隐私政策",
    inputInviteCode: "请输入邀请码(非必填)",
    inviteCode: "邀请码"
  },
  messages: {
    messageCenter: "消息中心",
    isTop: "置顶",
    unread: "未读"
  },
  // 订单
  order: {
    title: "订单",
    selectAddress: "请选择地址",
    shippingMethod: "配送方式：",
    freightAmount: "运费合计",
    firstWeight(val) {
      return "首重 " + val + "KG：";
    },
    secondWeight(val) {
      return "续重 " + val + "KG：";
    },
    total(val) {
      return "共 " + val + " 件";
    },
    priceDetail: "价格明细",
    shippingIncluded: "含运费",
    coupon: "优惠券",
    totalFreight: "总运费",
    discountAmount: "优惠金额",
    productTotal: "商品总价",
    allTotal: "合计",
    submitOrder: "提交订单",
    placeholderSelectAddressFirst: "请先选择地址",
    placeholderSelectAddress: "请选择地址",
    placeholderSelectFreight: "请选择配送方式",
    placeholderSelectEmpty: "该国家暂无配送方式，请更换地址",
    stateAll: "全部订单",
    stateUnpay: "待支付",
    stateWaitSending: "待发货",
    stateReceiving: "待收货",
    stateFinish: "已完成",
    noMore: "没有更多了",
    logistics: "查看物流",
    delete: "删除订单",
    confirm: "确认收货",
    ordersTtitleInfo: "件货品 总金额:(含运费$"
  },
  alert: {
    cancelButtonText: "取消",
    confirmButtonText: "确认",
    orderConfirmText: "是否确认收货?",
    orderCancelText: "确认取消订单?"
  },
  // 支付
  pay: {
    title: "支付",
    offlinePay: "线下支付",
    cardPay: "信用卡在线支付",
    lianlianPay: "连连在线支付",
    backHome: "返回首页",
    detail: "查看详情",
    success: "支付成功",
    failure: "支付失败",
    payType: "支付方式",
    onPay: "立即支付",
    payDetail: "支付详情",
    rePayDetail: "重新支付",
    cancelOrder: "取消订单",
    emptyPay: "暂无支付方式",
    selectPay: "请选择支付方式"
  },
  paymentInfo: {
    optional: "选填",
    required: "必填",
    haveAuth: "已认证",
    unAuth: "未认证",
    onAuth: "审核中",
    reAuth: "未通过",
    passPort: "驾照号/护照号",
    inputPassPort: "请输入驾照号/护照号",
    auth: "认证",
    nameAuth: "实名认证",
    checkAuth: "身份认证",
    goAuth: "去认证",
    title: "请上传支付凭证的截图，保证内容清晰可见",
    yourName: "名字",
    yourNamePlc: "请输入名字",
    bankName: "银行名称",
    bankNamePlc: "请输入银行名称",
    bankAccount: "银行账号",
    bankAccountPlc: "请输入银行账号",
    iBankNo: "IBank No.",
    iBankNoPlc: "请输入 IBank No.",
    swiftCode: "Swift Code",
    swiftCodePlc: "请输入 Swift Code",
    bankAddress: "银行地址",
    bankAddressPlc: "请输入银行地址",
    yourPostAdd: "邮寄地址",
    yourPostAddPlc: "请输入邮寄地址",
    reason: "原因",
    done: "完成",
    resubmit: "重新提交",
    authMessage: "您还未认证身份信息，请前去认证身份，完善资料",
    accountName: "账户名称",
    accountNumber: "账户号码",
    upload: "准备上传",
    verifying: "审核中",
    verifyFailed: "审核不通过",
    verifySuccess: "审核通过",
    verifyInfo: "请转账至下方银行账号，填写支付账户信息并提供银行转账凭证截图"
  },
  // 购物车
  cart: {
    title: "购物车",
    total(val) {
      return "共" + val + "件商品";
    },
    manage: "管理",
    finish: "完成",
    checkAll: "全选",
    excludeFreight: "不含运费",
    payMoney: "合计：",
    delete: "删除",
    clearing: "结算",
    recommend: "为您推荐",
    selectItems: "请选择商品",
    empty: "购物车是空的",
    gogogo: "去逛逛",
    productOff: "此商品已下架",
    productsOff: "这些 商品已下架 或 库存不足",
    inventory: "库存",
    inventoryState1: "库存不足",
    inventoryState2: "已售罄",
  },
  errorParamGoBack: "参数有误,请返回上一页操作",
  unselected: "未选择",
  // 地址管理
  address: {
    pleaseEnter: "请输入 ",
    name: "姓名",
    phone: "手机号码",
    address: "详情地址",
    postcode: "邮编",
    addAddress: "新增收货地址",
    country: "所在国家",
    city: "城市",
    consigneeName: "收货人姓名",
    setDefaultAddress: "设置为默认地址",
    select: "请选择"
  },
  editAddress: {
    done: "保存",
    delete: "删除"
  },
  second: "秒",
  name: "姓名",
  tel: "电话",
  save: "保存",
  confirm: "确认",
  cancel: "取消",
  delete: "删除",
  complete: "完成",
  loading: "加载中...",
  telEmpty: "请填写电话",
  nameEmpty: "请填写姓名",
  confirmDelete: "确定要删除么",
  telInvalid: "请填写正确的电话",
  forwardToEarn: "转发赚",
  // home国际化
  homeList: {
    Sold: "已售"
  },
  homeIcon: {
    newProduct: "今日新上",
    ladies: "女装服饰",
    digital: "数码产品",
    hotSale: "爆款热卖"
  },
  up: "起",
  sell: "已售",
  piece: "件",
  noMoreData: "更多精品即将上线!",
  noDetails: "暂无描述",
  supplier: "供应商",
  in: "进入",
  addToStore: "添加到商铺",
  forwardGoods: "转发卖货",
  belongToSupplier: "你是供应商的普通分销",
  notAreToSupplier: "你不是供应商的普通分销",
  attention: "关注",
  operateSuccessfully: "操作成功",
  forward: "转发",
  // 我的小店
  todayTurnover: "今日营业额",
  todayOrder: "今日的订单数",
  visitorsToday: "今日的房客数",
  earnings: "收益管理",
  commodity: "商品管理",
  indent: "订单管理",
  operatingData: "经营分析",
  tutorials: "教程",
  soldOut: "下架中",
  sold: "下架",
  putaway: "上架中",
  put: "上架中",
  all: "全部商品",
  sellingPrice: "售价",
  sales: "已售",
  earn: "赚",
  interpret: "翻译",
  unstick: "取消置顶",
  stick: "置顶",
  updatePrice: "修改价格",
  mainHeading: "主标题",
  subheading: "副标题",
  specification: "规格",
  cost: "成本",
  profit: "利润",
  msetKeyValue: "批量设置利润",
  discount: "优惠",
  // 用户管理
  userManage: {
    allOrders: "全部订单",
    notDeliverGoods: "待发货",
    confiscatedGoods: "待收货",
    haveFinished: "已完成",
    allGoods: "全部商品",
    attentionGoods: "我的收藏",
    attentionShop: "店铺关注",
    browsingHistory: "浏览记录",
    coupon: "优惠券",
    myOrder: "我的订单",
    waitPayment: "待付款",
    waitGoods: "待发货",
    haveGoods: "已收货"
  },
  couponInterpret: {
    state1: "待使用",
    state2: "已使用",
    state3: "已失效",
    state4: "不可用",
    goUse: "去使用",
    endDate: "到期时间",
    noCondition: "无门槛",
    condition(value) {
      return "满" + value + "使用";
    },
    totalCount(value) {
      return value + "张";
    },
    couponCount(value) {
      return value + "元优惠券";
    },
    alertTitle: "您已收到以下优惠券",
    selectionTitle: "请选择优惠券",
    selectedCoupon: "已选优惠券",
    discountTotal: "优惠合计",
    canuseTitle: "可使用的优惠券",
    notuseTitle: "不可使用的优惠券",
    notuseCoupon: "不使用优惠券",
    reason: "本单不可用原因: 不满足使用条件"
  },
  support: {
    title: "技术支持",
    dateHour: "工作时间：9:00-18:00",
    dateWeek: "工作天数: Monday – Friday",
    helpCenter: "联系我们",
    email: "邮箱",
    select: "请选择服务类型",
    supportOption1: "投诉与建议",
    supportOption2: "其他",
    enterType: "请选择类型",
    enterSupport: "请输入你的内容",
    enterEmail: "请输入邮箱地址",
    contactWay: "你的联系方式",
    supportList: "查看已提交的内容",
    serviceType: "服务类型",
    serviceTitle: "问题标题",
    serviceStatus: "问题状态",
    serviceTime: "提交时间",
    serviceDes: "问题描述",
    serviceContact: "联系方式",
    servicePosted: "发表人",
    serviceContent: "回复内容",
    serviceReplyTime: "回复时间",
    serviceReplyContent: "回复内容",
    serviceInputReplyContent: "请输入回复内容",
    copyRight: "版权所有"
  },
  goodsAttention: {
    sellingPrice: "售价"
  },
  // 订单详情
  orderDetails: {
    logisticsStatus: "物流状态",
    consignee: "收件人",
    shippingAddress: "收件地址",
    productTotalPrice: "货物总价",
    freight: "运费",
    actualPayment: "实付款",
    total: "合计",
    contactTheShop: "联系店铺",
    orderNumber: "订单号",
    logisticsNumber: "物流单号",
    orderTime: "下单时间",
    timeOfPayment: "付款时间",
    payment: "已付款",
    product: "货物",
    totalPrice: "总金额",
    copy: "复制",
    supplier: "供应商",
    unpayText(time) {
      return "请在 " + time + " 前支付，逾期订单将自动取消";
    },
    ordersDelivery(delivery) {
      return "(含总运费$ " + delivery + ")";
    },
    totalAmount: "件货物 总金额:"
  },
  // 用户设置
  settings: {
    userTerms: "平台条款",
    userRules: "平台规则",
    aboutUs: "关于我们",
    profilePhoto: "头像",
    nickname: "昵称",
    myShippingAddress: "我的收货地址",
    logOut: "退出登录"
  },
  deliveryAddress: {},
  // 搜索信息
  searchInfo: {
    synthesis: "综合",
    fixture: "成交数",
    intransit: "转发数"
  },
  // 详情与规格
  detailsInterpret: {
    productInfo: "产品信息",
    weight: "重量",
    bulk: "体积",
    noInformationAtPresent: "暂无描述",
    buy: "立即购买",
    addcart: "添加购物车",
    quantity: "购买数量",
    pleaseSelect: "请选择",
    submit: "确定",
    detailsSold: "销售",
    inventory: "库存",
    inventoryText: "此产品没有库存",
    inventoryLimit: "购买数量超出库存",
    productDescription: "产品描述",
    none: "无",
    upSold: number => {
      return number + "件起售";
    }
  },
  // c注册b
  authenticationInfo: {
    haveAuth: "已认证",
    unAuth: "未认证",
    onAuth: "审核中",
    reAuth: "未通过",
    passPort: "驾照号/护照号",
    inputPassPort: "请输入驾照号/护照号",
    auth: "认证",
    nameAuth: "实名认证",
    checkAuth: "注册店铺",
    goAuth: "去认证",
    title: "请上传清晰的驾驶证或护照图片（正反面）",
    authState: "认证状态",
    yourName: "名字",
    yourNamePlc: "请输入名字",
    bankName: "银行名称",
    bankNamePlc: "请输入银行名称",
    bankAccount: "银行账号",
    bankAccountPlc: "请输入银行账号",
    iBankNo: "IBank No.",
    iBankNoPlc: "请输入 IBank No.",
    swiftCode: "Swift Code",
    swiftCodePlc: "请输入 Swift Code",
    bankAddress: "银行地址",
    bankAddressPlc: "请输入银行地址",
    yourPostAdd: "邮寄地址",
    yourPostAddPlc: "请输入邮寄地址",
    done: "完成",
    reason: "原因",
    authMessage: "您未完成实名认证，请前往完成验证",
    submitMessage: "您的身份认证正在审核中，请耐心等候",
    failMessage: "您的身份认证未通过，请重新认证身份",
    shopName: "店铺名称",
    yourShopName: "请输入店铺名称"
  },
  todayData: "今日数据",
  turnover: "营业额",
  netIncome: "净收入",
  orderQuantity: "订单数",
  visitor: "访客数（人）",
  historicalData: "历史数据",
  historySearch: "历史搜索",
  cumulativeTurnover: "累计营业额",
  cumulativeNetIncome: "累计净收入",
  cumulativeOrder: "累计订单数",
  cumulativeVisitors: "累计访客数（人）",
  pleaseEnterContent: "请输入内容",
  browsingHistory: "浏览记录",
  myOrder: "我的订单",
  waitPayment: "待付款",
  waitGoods: "待发货",
  haveGoods: "已收货",
  unfollow: "取消关注",
  cancelCollection: "取消收藏",
  copySuccess: "已复制到粘贴板",
  nonsupportCopy: "此设备不支持",
  specialOffer: "特价优惠",
  cStore: {
    hotSale: "热销榜",
    allProducts: "全部商品",
    sold: "已售",
    pleaseEnterContent: "请输入内容"
  },
  plugins: {
    unavailable: "此功能需要访问客户端才能使用",
    download: "去下载"
  }
};
export default messages;
