import Vue from 'vue'
import { Dialog } from 'vant' // 弹框
import router from '@/router'
Vue.use(Dialog)
let dialog = Dialog // 弹框
// 显示弹框
export const showDialog = params => {
  const { msg, status } = params
  console.log('状态码' + status)
  dialog
    .alert({
      message: msg,
      className: 'showDialogClass'
    })
    .then(() => {
      dialog.close()
    })
}
export const hideDialog = () => {
  dialog.close()
}

//
export const transmission = params => {
  console.log('透传消息transmission')
  console.log(params)
  // 判断是否为登录消息
  if (params.type === 'login') {
    const pathname = location.pathname
    const url = window.location.href
    const index = url.indexOf(pathname)
    const redirect = url.slice(index)
    console.log(redirect)
    router.push({
      path: '/auth',
      query: { redirect: redirect }
    })
  } else {
    showDialog(params)
  }
}

export default {
  showDialog,
  hideDialog,
  transmission
}
