import Vue from 'vue'
import { Toast } from 'vant'
import { Language } from "@/language/index.js";

Vue.use(Toast)
let toast // 轻提示
let loading // loading
let loadingCount = 0
Toast.allowMultiple()
Toast.setDefaultOptions({ className: 'toastClass' })
Toast.setDefaultOptions('loading', { forbidClick: true })

// 操作成功失败轻提示 typename success,fail
export const showToast = params => {
  const { type, titleMsg } = params
  if (type === 'success') {
    toast = Toast.success(titleMsg)
  } else if (type === 'error') {
    // toast = Toast.setDefaultOptions("fail", { duration: 0 });
    toast = Toast.fail(titleMsg)
  }
}
export const hideToast = () => {
  toast.clear()
}
// loading
const startLoading = () => {
  loading = Toast.loading({
    message: Language.loading,
    forbidClick: true,
    duration: 0
  })
}

const endLoading = () => {
  loading.clear()
}

const showLoading = () => {
  if (loadingCount === 0) {
    startLoading()
  }
  loadingCount += 1
}

const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  if (loadingCount === 0) {
    endLoading()
  }
}

export { showLoading, hideLoading }
// export hideLoading

export default {
  showToast,
  hideToast,
  showLoading,
  hideLoading
}
