import {
  showToast,
  hideToast,
  showLoading,
  hideLoading
} from '@/plugins/environment/model/toast' // 轻提示+loading
import { showNotify, hideNotify } from '@/plugins/environment/model/notify' // 通知
import { showDialog, hideDialog, transmission } from '@/plugins/environment/model/dialog' // 弹框
import { picturePreview } from '@/plugins/environment/model/other' // 其他
import { routerPush, routerReplace, shareGoods, pop, backHome,backUser } from '@/plugins/environment/model/remould-router'
// import { from } from "rxjs";

export const typeName = {
  showToast,
  hideToast,
  showLoading,
  hideLoading,
  showNotify,
  hideNotify,
  showDialog,
  hideDialog,
  routerPush,
  routerReplace,
  shareGoods,
  pop,
  picturePreview,
  transmission,
  backHome,
  backUser
}
