import Vue from "vue";
import VueRouter from "vue-router";
import webRouter from "./webRouter";
import { getRoute } from "@environment/type";

Vue.use(VueRouter);

let routes = [
  {
    path: getRoute(),
    component: _ => import("@/components/appHome/AppHome.vue"),
    children: webRouter,
    meta: {
      headerHide: true
    }
  },
  {
    path: "*",
    redirect: getRoute()
  }
];

routes = new Set([...webRouter, ...routes]);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 置顶
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
