import axios from "axios";
import Loacle from "../language/index";
import { Language } from "../language";

const http = axios.create({
  baseURL: process.env.VUE_APP_BASH_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": Loacle.language
  }
});
// let env = process.env.VUE_APP_ENVIRONMENT
// http request 请求拦截器，有token值则配置上token值
http.interceptors.request.use(
  config => {
    // console.log(env)
    // 添加多语言
    // const language = localStorage.getItem('language')
    //   ? localStorage.getItem('language')
    //   : 'en'
    // let newData = config.data
    // newData.language = language
    // config.data.language = Loacle.language
    // const login = {
    //   userName: 54545454,
    //   token: 'shfhsdjfhsdifh654654sdkojfdsjfsjdfd'
    // }
    // localStorage.setItem('auth', JSON.stringify(login))
    let { token } = JSON.parse(localStorage.getItem("auth")) || {};
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
http.interceptors.response.use(
  response => {
    const data = response.data;
    if (data.StatusCode === 200) {
      return {
        code: 200,
        data: data.Data,
        message: data.Message,
        meta: {
          page: data.PageIndexs,
          total: data.DataCounts,
          lastPage: data.ALLPages
        }
      };
    } else {
      const error = { code: data.StatusCode, message: data.Message };
      return Promise.reject(error);
    }
  },
  error => {
    let customError;
    if (error && error.response && error.response.data) {
      // if (
      //   error.response.data.StatusCode === 401 ||
      //   error.response.data.StatusCode === 403
      // ) {
      //   router.push({
      //     path: ''
      //   })
      // }
      if (
        error.response.data.StatusCode === 403 ||
        error.response.data.StatusCode === 401
      ) {
        // 登录过期删除用户缓存信息
        localStorage.removeItem("auth");
      }
    } else {
      customError = { code: 0, message: Language.meta.nextworkError, status: 400 };
    }
    // console.log(error)
    // console.log('Error', error.response)
    return Promise.reject(customError);
  }
);

export default http;
