export default {
  money (val) {
    var str = val.toFixed(2) + ''
    var intSum = str
      .substring(0, str.indexOf('.'))
      .replace(/\B(?=(?:\d{3})+$)/g, ',') // 取到整数部分
    var dot = str.substring(str.length, str.indexOf('.')) // 取到小数部分搜索
    var ret = intSum + dot
    return ret
  },
  imageSrc (val) {
    if (val.indexOf('http://') === 0 || val.indexOf('https://') === 0) {
      return val
    }
    const bashImageUrl = process.env.VUE_APP_BASH_IMAGE_URL
    if (val.indexOf('/') === 0) {
      return bashImageUrl + val.slice(1)
    }
    return bashImageUrl + val
  },
  splitFirstItem (val) {
    return val.split('|')[0]
  },
  imgUrlFilter (value) {
    value = value.split('|')[0]
    if (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) {
      return value
    }
    const bashImageUrl = process.env.VUE_APP_BASH_IMAGE_URL
    if (value.indexOf('/') === 0) {
      return bashImageUrl + value.slice(1)
    }
    return bashImageUrl + value
    // return this.imageSrc(value.split('|')[0])
  }
}
