<template>
  <div class="app">
    <header v-if="!$route.meta.headerHide && $route.path != '/'">
      <van-nav-bar
        :title="$route.meta.title"
        :left-arrow="$route.meta.leftBtnHide ? false : true"
        @click-left="onClickLeft"
      ></van-nav-bar>
    </header>
    <div
      :class="[
        'middle',
        $route.path.includes('auth') || $route.path == '/' + route
          ? 'none-bottom'
          : 'mb-bottom',
      ]"
    >
      <router-view />
    </div>
    <footer>
      <div v-show="$route.path.includes('home') && $route.meta.footerShow">
        <van-notice-bar
          color="#1989fa"
          background="#ecf9ff"
          :left-icon="downIcon"
          mode="link"
          @click="onDownload"
          v-if="route == 'web' && !active"
        >
          {{ $t.meta.downloadText }}
        </van-notice-bar>
      </div>
      <van-tabbar
        v-show="$route.meta.footerShow"
        v-model="active"
        active-color="#04ab3f"
        inactive-color="#A8A9A9"
        safe-area-inset-bottom
      >
        <van-tabbar-item
          v-for="(item, index) in tabbars"
          :key="index"
          :to="item.path"
          @click="onChange(index, item.path, item.name)"
        >
          <span>{{ item.title }}</span>
          <template slot="icon" slot-scope="props">
            <img :src="props.active ? item.active : item.normal" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import {
  Grid,
  GridItem,
  Icon,
  Button,
  Tabbar,
  TabbarItem,
  NavBar,
  NoticeBar,
} from "vant";
import { getRoute } from "@environment/type";

Vue.use(NavBar).use(NoticeBar);
export default {
  name: "App",
  components: {
    [Button.name]: Button,
    [Grid.name]: Grid,
    [Icon.name]: Icon,
    [GridItem.name]: GridItem,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    [NoticeBar.name]: NoticeBar,
  },
  data() {
    return {
      active: 0,
      route: "app",
      tabbars: [
        {
          path: getRoute() + "home",
          name: "Home",
          title: this.$t.meta.homePage,
          normal: require("@/assets/img/home/icon_tab_home@2x.png"),
          active: require("@/assets/img/home/icon_tab_home_select@2x.png"),
        },
        {
          path: getRoute() + "cart",
          name: "Cart",
          title: this.$t.cart.title,
          normal: require("@/assets/img/home/icon_my_shopping@2x.png"),
          active: require("@/assets/img/home/icon_my_shopping_select@2x.png"),
        },
        {
          path: getRoute() + "user/userManage",
          name: "User",
          title: this.$t.meta.userPage,
          normal: require("@/assets/img/home/icon_tab_my@2x.png"),
          active: require("@/assets/img/home/icon_tab_my_select@2x.png"),
        },
      ],
      downIcon: require("@/assets/img/home/download.png"),
    };
  },
  methods: {
    onDownload() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.prolinker.mall"
      );
    },
    onChange(index) {
      this.active = index;
      if (this.active == index) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    onClickLeft() {
      if (this.$route.meta.backUser) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.backUser);
      } else if (this.$route.meta.backHome) {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.backHome);
      } else {
        this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
      }
    },
  },
  created() {
    this.active = 0;
    this.route = getRoute(true);
  },
  updated() {
    //console.log(this.$route.path);
    if (this.$route.path.includes("home")) {
      this.active = 0;
    } else if (this.$route.path.includes("cart")) {
      this.active = 1;
    } else if (this.$route.path.includes("user")) {
      this.active = 2;
    } else {
      this.active = 0;
    }
  },
};
</script>
<style >
</style>
<style scoped>
.app {
}
header {
  height: auto;
  background: linear-gradient(left, #71d283, #01aaa3);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
  height: auto;
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}
header img {
  width: 0.66rem;
  height: 0.57rem;
  vertical-align: middle;
}
footer {
  height: auto;
  line-height: 1rem;
  color: #fff;
}

footer img {
  width: 1.5rem;
  height: 1.5rem;
}
footer /deep/ .van-tabbar {
  height: auto;
  color: #04ab3f;
  z-index: 100 !important;
  padding-top: 5px;
  padding-bottom: calc(5px + env(safe-area-inset-bottom));
}
[class*="van-hairline"]::after {
  /* border: 0 solid #0f0f1a; */
}
.van-notice-bar {
  position: fixed;
  bottom: 50px;
  left: 0px;
  width: -webkit-fill-available;
  opacity: 0.9;
  line-height: normal;
}
footer /deep/ .van-notice-bar__left-icon {
  font-size: 25px;
  margin-right: 5px;
  margin-bottom: 2px;
}
.middle {
}
.none-bottom {
  margin-bottom: 0vw;
}
.mb-bottom {
  margin-bottom: 30vw;
}
</style>