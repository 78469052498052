import http from "../utils/http";
function request(url, method = "get", data = {}, headers = {}) {
  return http({
    url,
    method,
    data,
    headers
  });
}
function get(url, data = {}, headers = {}) {
  return request(url, "get", data, headers);
}

function post(url, data = {}, headers = {}) {
  return request(url, "post", data, headers);
}

const api = {
  imageUrl: process.env.VUE_APP_BASH_IMAGE_URL,
  videoUrl: process.env.VUE_APP_BASH_VIDEO_URL,
  formatImageUrl(url) {
    const bashImageUrl = this.imageUrl;
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      return url;
    }
    if (url.indexOf("/") === 0) {
      return bashImageUrl + url.slice(1);
    }
    return bashImageUrl + url;
  },
  formatVideoUrl(url) {
    if (!url) return null;
    const bashVideoUrl = this.videoUrl;
    if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
      return url;
    }
    if (url.indexOf("/") === 0) {
      return bashVideoUrl + url.slice(1);
    }
    return bashVideoUrl + url;
  },
  formatVtrackUrl(url) {
    const vtrackImageUrl = "https://api.vtrack.top";
    return vtrackImageUrl + url;
  },
  search: {
    // 加载搜索推荐关键词
    loadSearchKeyWords: data => {
      return post("api/C_Search/LoadSearchKeyWords", data);
    },
    // 根据传入搜索关键字加载分页产品
    loadSearchProductByPage: data => {
      return post("api/C_Search/LoadSearchProductByPage", data);
    }
  },
  index: {
    // 首页数据
    loadIndex: data => {
      return post("api/C_Index/C_LoadIndex", data);
    },
    // 新首页数据
    newLoadAppIndex: data => {
      return post("api/C_Index/C_LoadAppIndex", data);
    },
    // 全部商品
    loadCProductByPage: data => {
      return post("api/C_Index/LoadCProductByPage", data);
    },
    // 首页数据
    loadAppIndex: data => {
      return post("api/C_Index/C_LoadAppIndex", data);
    },
    // 获取热门
    loadSpecialProduct: data => {
      return post("api/C_Index/C_LoadSpecialProduct", data);
    },
    // 获取全部分类api/C_Product/LoadProductClass
    loadProductClass: data => {
      return post("api/C_Product/LoadProductClass", data);
    }
  },
  login: {
    // 用户登录
    userLogin: data => {
      return post("api/C_Login/C_UserLogin", data);
    },
    // 注册
    registerUser: data => {
      return post("api/C_Login/C_RegisterUser", data);
    },
    // 退出登录
    loginOut: data => {
      return get("api/C_Login/LoginOut", data);
    },
    // 验证账号是否注册
    checkRegisterUser: data => {
      return post("api/C_Login/CheckRegisterUser", data);
    },
    // 当旧密码没有发送时会当做忘记密码直接修改密码，发送了旧密码会验证旧密码再修改密码 ,如果只发送新密码需要搭配验证码一起发送
    changeUserPassword: data => {
      return post("api/C_Login/C_ChangeUserPassword", data);
    },
    // 获取用户信息
    getUserInfo: data => {
      return post("api/C_Users/C_GetUserInfo", data);
    }
  },
  user: {
    // 获取用户信息
    getUserInfo: data => {
      return post("api/C_Users/C_GetUserInfo", data);
    },
    // 获取用户收货地址（已实现）
    loadUsersReceiving: data => {
      return post("api/C_Users/LoadUsersReceiving", data);
    },
    // 增加收货地址（已实现
    addUsersReceiving: data => {
      return post("api/C_Users/AddUsersReceiving", data);
    },
    // 根据传入的编号删除收货地址（已实现）
    deleteUsersReceiving: data => {
      return post("api/C_Users/DeleteUsersReceiving", data);
    },
    // 批量删除收货地址（已实现
    deleteShopCarByList: data => {
      return post("api/C_Users/DeleteShopCarByList", data);
    },
    // 修改用户信息
    EditUserInfo: data => {
      return post("api/C_Users/C_EditUserInfo", data);
    }
  },
  shop: {
    // 获取店铺信息
    loadShopInfo: headers => {
      return post("api/C_Shop/LoadShopInfo", {}, headers);
    },
    // 加载店铺（包含热销产品，全部产品
    loadShop: data => {
      return post("api/C_Shop/LoadShop", data);
    },
    // 加载店铺产品（分页
    loadShopProductByPage: data => {
      return post("api/C_Shop/LoadShopProductByPage ", data);
    },
    //获取认证信息
    loadShopCheckInfo: data => {
      return post("api/B_Shop/LoadShopCheckInfo", data);
    }
  },
  shopCar: {
    // 分页获取用户购物车信息（已实现
    loadShopCarByPage: data => {
      return post("api/C_ShopCar/LoadShopCarByPage", data);
    },
    // 添加产品到用户的购物车（已实现
    addShopCar: data => {
      return post("api/C_ShopCar/AddShopCar", data);
    },
    // 根据传入的购物车编号删除购物车内数据（已实现
    deleteShopCar: data => {
      return post("api/C_ShopCar/DeleteShopCar", data);
    },
    // 批量删除购物车（已实现）
    deleteShopCarByList: data => {
      return post("api/C_ShopCar/DeleteShopCarByList", data);
    }
  },
  product: {
    // C端获取商品详情信息（已实现）
    loadProductInfo: data => {
      return post("api/C_Product/C_LoadProductInfo", data);
    },
    recommendProduct: data => {
      return post("api/C_Product/RecommendProduct", data);
    }
  },
  follow: {
    // 关注、取消关注 店铺（已完成)
    addFollowShop: data => {
      return post("api/C_Follow/C_AddFollowShop", data);
    },
    // 添加/取消添加 产品到我喜爱（Token）
    addFavoriteProduct: data => {
      return post("api/C_Follow/C_AddFavoriteProduct", data);
    },
    // 关注供应商分页列表（已完成）
    followShopByPage: data => {
      return post("api/C_Follow/C_FollowShopByPage", data);
    },
    // 我喜爱的产品分页列表（已完成）
    favoriteProductByPage: data => {
      return post("api/C_Follow/C_FavoriteProductByPage", data);
    },
    // 我的足迹
    footmarkByPage: data => {
      return post("api/C_Follow/C_FootmarkByPage", data);
    }
  },
  system: {
    // 获取支持的国家
    getCountrys: data => {
      return get("api/C_System/GetCountrys", data);
    },
    // 通用发送邮件验证码（已实现
    sendEmailCode: data => {
      return post("api/C_System/SendEmailCode", data);
    },
    // 验证邮箱验证码是否正确
    checkEmailCode: data => {
      return post("api/C_System/CheckEmailCode", data);
    },
    // 根据传入的OSVersions 获取对应的app版本信息
    loadAppVersion: data => {
      return post("api/C_System/B_LoadAppVersion", data);
    },
    // 上传图片（已实现）
    uploadImgFile: data => {
      return post("api/C_System/C_UploadImgFile", data);
    },
    //获取平台消息列表
    messageList: data => {
      return post("api/C_System/GetPlatformMessageContentList", data);
    },
    //获取平台消息详情
    messageInfo: data => {
      return post("api/C_System/GetPlatformMessageContentInfo", data);
    },
    //获取服务类型列表
    getSeriveType: data => {
      return post("api/C_System/GetServiceTypeList", data);
    },
    //添加问题反馈
    addProblemFeedback: data => {
      return post("api/C_System/AddProblemFeedback", data);
    },
    //问题反馈列表
    supportByPage: data => {
      return post("api/C_System/LoadProblemFeedbackByPage", data);
    },
    //问题反馈详情
    getSupportInfo: data => {
      return post("api/C_System/LoadProblemFeedbackInfo", data);
    },
    //问题反馈回复
    addSupportReply: data => {
      return post("api/C_System/AddProblemReply", data);
    }
  },
  users: {
    // 获取用户收货地址（已实现
    loadUsersReceiving: data => {
      return post("api/C_Users/LoadUsersReceiving", data);
    },
    // 增加收货地址（已实现）
    addUsersReceiving: data => {
      return post("api/C_Users/AddUsersReceiving", data);
    },
    // 根据传入的编号删除收货地址（已实现
    deleteUsersReceiving: data => {
      return post("api/C_Users/DeleteUsersReceiving", data);
    },
    // 批量删除收货地址（已实现）
    deleteShopCarByList: data => {
      return post("api/C_Users/DeleteShopCarByList", data);
    },
    loadUsersReceivingInfo: data => {
      return post("api/C_Users/LoadUsersReceivingInfo", data);
    }
  },
  order: {
    loadOrderPreview: data => {
      return post("api/C_Order/C_LoadOrderPreview", data);
    },
    addOrderPreview: data => {
      return post("api/C_Order/C_AddOrderPreview", data);
    },
    payBack: data => {
      return post("api/C_Order/C_PayBack", data);
    },
    loadOrderByPage: data => {
      return post("api/C_Order/C_LoadOrderByPage", data);
    },
    cancelOrder: data => {
      return post("api/C_Order/CancelOrder", data);
    },
    loadOrderInfo: data => {
      return post("api/C_Order/C_LoadOrderInfo", data);
    },
    loadOrderLogistics: data => {
      return post("api/C_Order/C_LoadOrderLogistics", data);
    },
    generatePaymentLinks: data => {
      return post("api/C_Order/" + data.PaymentLink, data);
    },
    // 新下单
    orderPreviewByCroPay: data => {
      return post("api/C_Order/C_AddOrderPreviewByCroPay", data);
    },
    //获取第三方支付链接
    getPaymentLinks: data => {
      return post("api/C_Order/C_GeneratePaymentLinks", data);
    },
    //获取订单线下支付记录
    GetOrderLinePaymentRecord: data => {
      return post("api/C_Order/GetOrderLinePaymentRecord", data);
    },
    //获取VanCheen默认收款账户信息
    GetVanCheenBankAccountInfo: data => {
      return post("api/C_Order/GetVanCheenBankAccountInfo", data);
    },
    //提交线下支付表单
    AddOrderLinePaymentRecord: data => {
      return post("api/C_Order/AddOrderLinePaymentRecord", data);
    },
    //确认收货
    ConfirmReceipt: data => {
      return post("api/C_Order/ConfirmReceipt", data);
    },
    //根据支付方式获取渠道商列表
    ChannelDealerListByPaymentWay: data => {
      return post("api/C_Order/C_ChannelDealerListByPaymentWay", data);
    }
  },
  coupon: {
    //用户确认接收优惠券
    userConfirmCoupon: data => {
      return post("api/C_Users/UserConfirmCoupon", data);
    },
    //获取用户优惠券列表
    getUserCouponListByPage: data => {
      return post("api/C_Users/GetUserCouponListByPage", data);
    },
  },
  userB: {
    // api/B_Login/RegisterShopCheckInfo
    registerShopCheckInfo: data => {
      return post("api/B_Login/RegisterShopCheckInfo", data);
    },
    // 上传图片
    uploadImgFile: data => {
      return post("api/B_System/UploadImgFile", data);
    },
    //获取店铺信息
    loadShopInfo: data => {
      return post("api/B_Shop/LoadShopInfo", data);
    }
    // uploadImgFile: (file, headers, onUploadProgress) => {
    //   let type = file.type;
    //   let data = new FormData();
    //   data.append("file", file, file.name);
    //   let _headers = headers;
    //   _headers["Content-Type"] = type;
    //   _headers[
    //     "Content-Disposition"
    //   ] = `form-data; name="pc"; filename="${encodeURI(file.name)}"`;
    //   return http({
    //     url: "api/B_System/UploadImgFile",
    //     method: "post",
    //     headers: _headers,
    //     data,
    //     onUploadProgress
    //   });
    // }
  }
};

export default api;
