import { Locale } from 'vant'
const kLanguage = 'language'

function getQueryVariable (variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return (false)
}

var localLanguage = getQueryVariable('language') || navigator.language || navigator.userLanguage || localStorage.getItem(kLanguage) || 'en-US'// 常规浏览器语言和IE浏览器
localLanguage = localLanguage.substr(0, 2) // 截取lang前2位字符

var language
var originLanguage

switch (localLanguage) {
  case 'ar':
    language = require('./lang/ar-JO').default
    originLanguage = 'ar'
    break
  case 'en':
    language = require('./lang/en-US').default
    originLanguage = 'en'
    break
  case 'zh':
    language = require('./lang/zh-CN').default
    originLanguage = 'zh'
    break
  default:
    language = require('./lang/en-US').default
    originLanguage = 'en'
}
console.log(localLanguage, language)

Locale.use(localLanguage, language)
// 写入
localStorage.setItem(kLanguage, originLanguage)

export const Language = language

export default {
  install: function (Vue) {
    Vue.prototype.$t = language
  },
  language: originLanguage
}
