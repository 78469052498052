import { SET_AUTH, LOG_OUT } from "./mutation-types";
// import { isAPP } from '@/plugins/environment/type.js'
const kAuth = "auth";
const {
  userName,
  userId,
  shopId,
  token,
  IsShopCheck,
  IsShopSubmit
} = JSON.parse(localStorage.getItem(kAuth)) || {
  userName: null,
  userId: null,
  shopId: null,
  token: null,
  IsShopCheck: 0,
  IsShopSubmit: 0
};
const isOpenApp = localStorage.getItem("isOpenApp") || 0; // 是否提示打开过app
// 获取urltoken
const getQueryVariable = variable => {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return "-1";
};

export default {
  state: {
    userName: userName,
    userId: userId,
    shopId: shopId,
    token: token,
    IsShopCheck: IsShopCheck,
    IsShopSubmit: IsShopSubmit,
    isOpenApp: isOpenApp
  },
  getters: {
    isLogging(state) {
      if (getQueryVariable("token") !== "-1" && !state.token) {
        const auth = {
          userName: null,
          token: getQueryVariable("token")
        };
        localStorage.setItem(kAuth, JSON.stringify(auth));
        return true;
        // } else if (state.userName && state.token) {
      } else if (state.token) {
        return true;
      } else {
        return false;
      }
    },
    tokenHeaders(state, getters) {
      if (!getters.isLogging) return {};
      return { Authorization: "Bearer " + state.token };
    }
  },
  mutations: {
    [SET_AUTH](state, data) {
      const {
        UserName,
        UserId,
        ShopId,
        Ticket,
        IsShopCheck,
        IsShopSubmit
      } = data;
      if (UserName) state.userName = UserName;
      if (UserId) state.userId = UserId;
      if (ShopId) state.shopId = ShopId;
      if (Ticket) state.token = Ticket;
      state.IsShopCheck = IsShopCheck;
      state.IsShopSubmit = IsShopSubmit;
      const login = {
        userName: state.userName,
        userId: state.userId,
        shopId: state.shopId,
        token: state.token,
        IsShopCheck: state.IsShopCheck,
        IsShopSubmit: state.IsShopSubmit
      };

      localStorage.setItem(kAuth, JSON.stringify(login));
    },
    [LOG_OUT](state) {
      state.userName = null;
      state.userId = null;
      state.shopId = null;
      state.token = null;
      state.IsShopCheck = 0;
      state.IsShopSubmit = 0;
      const login = {
        userName: null,
        userId: null,
        shopId: null,
        token: null,
        IsShopCheck: 0,
        IsShopSubmit: 0
      };
      localStorage.setItem(kAuth, JSON.stringify(login));
      localStorage.setItem("isOpenApp", 0);
    }
  }
};
