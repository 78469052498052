import * as types from './mutation-types'
const getProductInfo = localStorage.getItem('productInfo') || {}
export default {
  state: {
    productInfo: getProductInfo // 商品信息
  },
  getters: {
  },
  mutations: {
    [types.SET_PRODUCTINFO] (state, data) {
      const productInfo = data
      state.productInfo = productInfo
      localStorage.setItem('productInfo', productInfo)
    }
  }
}
